.background-image{
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.background-image .box{
  background-color: #fff;
  width: 500px;
  border-radius: 30px;
  padding: 20px;
}

@media (max-width: 950px) {
  .background-image .box{
    width: 80%;
  }
}