body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-image: '/logo.webp';
  background-size: cover; /* Ajusta a imagem para cobrir toda a página */
  background-position: center; /* Centraliza a imagem */
  background-repeat: no-repeat; /* Evita que a imagem se repita */
}

paleta-cor5

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
  
.paleta-cor1{
  background-color: #b9936c;
}

.paleta-cor1-color{
  color: #7c4906;
}

.paleta-cor2{
  background-color: #dac292;
}

.paleta-cor3{
  background-color: #e6e2d3;
}

.paleta-cor4{
  background-color: #c4b7a6;
}

.background-image-bege2{
  background-image: url('/public/textura-bege2.png');
  background-size: cover;
  background-position: bottom;
}
