@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
  
  .phrase-section {
    flex: 1;
    min-width: 250px;
    text-align: center;
    margin: 0;
    padding: 0;  
  }

  .phrase-title{
    font-family: "Montserrat", cursive;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 0;
    font-size: 2.3rem;
    padding: 0;
    margin: 0;
  }

  .background-image-phase{
    background-image: url('/public/textura-bege.png');
    background-size: cover;
    background-position: bottom;
  }