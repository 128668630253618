.review-card{
  background: #fff;
  border-radius: 15px;
  width: 300px;
  height: auto;
  padding: 20px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}
.review-card:after{ /*Triangulo*/
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #fff;
  bottom: -20px; 
  right: 20%;
  
}