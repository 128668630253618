.footer-section {
  width: 100%; 
  padding: 10px 0; 
  text-align: center; 
  box-sizing: border-box;
  color: #E0DED9;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 10px; 
}