@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
  
  .paragraph-section {
    flex: 1;
    min-width: 250px; 
    margin: 0;
    padding: 0;  
    
  }

  .paragraph-section p, li{
    font-size: 1.2rem;
  }

  .paragraph-section li{
    list-style-type: circle;
    position: relative;
    left: 3%;
  }

  .subtitle-text{
    display: flex;
    align-items: center; 
    justify-content: center;
  }  

  .hr-middle-style{
    margin-top: 30px;
  }
