.info-button {
    display: flex;
    align-items: center; /* Garante que o ícone e o texto estejam alinhados verticalmente */
    gap: 10px; /* Adiciona um espaço entre o ícone e o texto */
    padding: 12px 12px; /* Ajusta o preenchimento */
    max-width: 60%;
    border-radius: 10px; /* Ajuste para mais ou menos arredondado conforme necessário */
    display: flex;
    align-items: center; /* Centraliza o conteúdo verticalmente */
    justify-content: center; /* Centraliza o conteúdo horizontalmente */
    font-weight: bold;
    font-size: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
  }

  .paleta-cor1:hover {
    background-color: #b47d46; /* Cor do botão ao passar o mouse */
  }

  
  .paleta-cor2:hover {
    background-color: #A6927D; /* Cor do botão ao passar o mouse */
  }