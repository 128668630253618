.review-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centraliza horizontalmente */

}

.review-item {
    margin: 0 10px 0 10px;
}

.background-image-review{
    background-image: url('/public/beige-abstract-wallpaper-background-image.webp');
    background-size: cover;
    background-position: bottom;
  }