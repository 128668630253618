@import url('https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,200;0,300;0,400;0,700;0,800;1,400&display=swap');

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b9936c;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.namePsi{
  font-family: "Neuton", serif;
  line-height: 4px;
  text-align: start;
  font-style: normal;
  margin-bottom: 0;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  height: 4rem;
}

.logo {
  display: flex;
  align-items: center; 
  margin-left: 10%;
}

.logo img {
  max-height: 4rem;
  width: auto; 
  object-fit: contain;
}

.logo span {
  font-size: 1rem; 
  color: #333; 
}

.contact-info {
  flex-basis: 20%;
  gap: 10px; 
  padding: 12px 12px; 
  max-width: 60%;
  border-radius: 10px; 
  display: flex;
  align-items: center; 
  justify-content: center; 
  font-weight: bold;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
  margin-right: 22%;
}

.contact-info:hover {
  background-color: #21c25c; 
  border: 2px solid #0e923e;
}

.contact-info {
  display: flex;
  align-items: center; /* Garante que o ícone e o texto estejam alinhados verticalmente */
  gap: 10px; /* Adiciona um espaço entre o ícone e o texto */
  padding: 5px 10px; /* Ajusta o preenchimento */
  max-height: 70px;
  max-width: 250px;
  border: 2px solid #1db856; /* Define a espessura e a cor da borda */
  border-radius: 10px; /* Ajuste para mais ou menos arredondado conforme necessário */
  display: flex;
  align-items: center; /* Centraliza o conteúdo verticalmente */
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  background-color: #25D366;
  color: #304A6F;
  font-weight: bold;
}


@media (max-width: 1500px) {
  .contact-info {
    margin-right: 12%;
  }
}



@media (max-width: 950px) {
  .contact-info {
    display: none;
  }

  .logo {
    margin-left: 0;
  }
}