@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #524C49;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #F8F8F8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border {
  border-top: 1px solid #e2e2e2;
}

.page-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 1% 12% 0 12%;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center; 
}



@media (max-width: 950px) {
  .page-container{
    margin: 4% 10% 0% 10%;
    padding-bottom: 20px;
  }
}

.text-title{
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
  font-size: 2rem;
  padding: 0;
  margin: 0;
  /* color: #CDB8A5; */
}

.alex-brush-subtitle{
  font-size: 2rem;
  font-family: "Alex Brush", cursive;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
  display: flex;
}

.montserrat-subtitle{
  font-size: 1.1rem;
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  margin-bottom: 0;
  font-style: italic;
}



.alex-brush{
  font-family: "Alex Brush", cursive;
}

.montserrat{
  font-family: "Montserrat", sans-serif;
}

.rose-color{
  color: #C2B2A3;
}

.rose-background{
  background-color: #C2B2A3;
}

.white-color{
  color: #F8F7F3;
}

.white-color{
  color: #F8F7F3;
}

.navy-color{
  color: #304A6F;
}

.white-background-color{
  background-color: #F8F7F3;
}

.navy-background{
  background-color: #304A6F;
}

hr{
  border: 0; /* Remove a borda padrão */
  height: 1px; /* Define a espessura da linha */
  background-color: #CDB8A5; /* Define a cor da linha */
  width: 100%; /* Ajusta a largura da linha conforme necessário */
  margin: 0;
  display: flex;
  /*margin-left: 5px;
  margin-right: 22px;*/
}

.button-section{
  padding-top: 20px;
}

.light-blue-background{
  background-color: #94AFCA;
}

.retreat{
  text-indent:4em
}

@media (max-width: 950px) {
  .retreat{
    text-indent: 0;
  }

  .text-align-left{
    text-align: center !important; 
  }
}

.text-align-left{
  text-align: left;
}

.text-align-center{
  text-align: center;
}

@media (max-width: 950px) {
  .button-section{
    display: flex;
    justify-content: center; /* centraliza horizontalmente */
    align-items: center; /* centraliza verticalmente */
  }
}