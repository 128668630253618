div#whatasapp {
  position: fixed;
  right: 20px;
  bottom: 10px;
  background: #0d7b00;
  z-index: 999;
  padding: 15px;
  border-radius: 50px;
  transition: .5s;
}

div#whatasapp:hover {
  right: 0;
  bottom: 0;
  border-radius: 140px 0px 0px 0px;
  padding: 50px;
  transition: .5s;
}